import React from 'react';
import styled from 'styled-components';
import { CustomLink as Link } from 'styles/Links';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';

type Props = {
  id: string;
  title: string;
  description: string;
  img: string;
};

export default function ProjectCard(props: Props) {
  return (
    <CustomCard>
      <Link to={`/projects/${props.id}`}>
        <CardActionArea>
          <CardImage image={props.img} title='Project banner' />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              {props.title}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              noWrap
            >
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </CustomCard>
  );
}

const CustomCard = styled(Card)`
  height: 290px;
`;

const CardImage = styled(CardMedia)`
  height: 150px;
`;
