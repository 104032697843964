import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  CircularProgress,
  Typography,
  Button,
  Grid,
  Chip,
  Link,
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { Center, ContentLayout } from "styles/Layout";
import { fetchProject } from "utils";

export default function Project() {
  const { id } = useParams();
  const [project, setProject] = useState<IProject | null>();

  useEffect(() => {
    const proj = fetchProject(id);
    if (proj) {
      setProject(proj);
    }
  }, [id]);

  if (project) {
    return (
      <ContentLayout>
        <Grid container direction="column" spacing={3} justify="center">
          <Grid item xs>
            <ProjectBanner>
              <img alt="project banner" src={project.img} width="100%" />
            </ProjectBanner>
          </Grid>
          <br />
          <Center>
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                {project.technologies.map((tech) => (
                  <CustomChip label={tech} color="secondary" key={tech} />
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  {project.title}
                </Typography>
              </Grid>
              {project.link && (
                <Grid item xs={12}>
                  <Link href={project.link}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      endIcon={<Launch />}
                    >
                      Check it out here
                    </Button>
                  </Link>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body1">{project.description}</Typography>
              </Grid>
            </Grid>
          </Center>
        </Grid>
      </ContentLayout>
    );
  } else {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
}

const ProjectBanner = styled.div`
  max-height: 500px;
  overflow: hidden;
`;

const CustomChip = styled(Chip)`
  margin: 4px 8px 4px 0;
`;
