import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CustomLink = styled(Link)`
  text-decoration: none;
  color: #000;

  :visited {
    text-decoration: none;
    color: #000;
  }
`;

export const CustomAnchor = styled.a`
  text-decoration: none;
  color: #000;

  :visited {
    text-decoration: none;
    color: #000;
  }
`;
