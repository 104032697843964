const INCREMENT = 'INCREMENT';
const DECREMENT = 'DECREMENT';

interface Action {
  type: string;
  payload: any;
}

const initialState = {
  counter: 0,
};

export default function exampleReducer(state = initialState, action: Action) {
  switch (action.type) {
    case INCREMENT: {
      return { ...state, counter: state.counter + 1 };
    }
    case DECREMENT: {
      return { ...state, counter: state.counter - 1 };
    }
    default:
      return state;
  }
}

export function incrementCounter() {
  return {
    type: INCREMENT,
  };
}

export function decrementCounter() {
  return {
    type: DECREMENT,
  };
}
