import data from 'assets/projects.json';

export function fetchProject(id: string) {
  const projects = data.projects;
  for (let i = 0; i < projects.length; i++) {
    if (projects[i].id === id) {
      return projects[i];
    }
  }
  return undefined;
}
