import React from 'react';
import Routes from 'navigation/Routes';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

export default function App() {
  return (
    <div>
      <Navbar />
      <Routes />
      <Footer />
    </div>
  );
}
