import React from 'react';
import { Grid } from '@material-ui/core';
import { ContentPadded, Divider } from 'styles/Layout';
import styled from 'styled-components';
import { CustomLink as Link } from 'styles/Links';
import ContactInfo from 'components/ContactInfo';
import Logo from 'assets/logo-white.svg';

export default function Footer() {
  return (
    <CustomContentLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link to='/'>
            <img src={Logo} alt='Logo' width={120} />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ContactInfo color='#FFFFFF' />
        </Grid>
      </Grid>
    </CustomContentLayout>
  );
}

const CustomContentLayout = styled(ContentPadded)`
  background-color: #00031c;
`;
