import React from 'react';
import ContactInfo from 'components/ContactInfo';
import { CenterLeft } from 'styles/Layout';
import { ContentLayout } from 'styles/Layout';
import { Grid, Typography } from '@material-ui/core';

export default function Contact() {
  return (
    <ContentLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CenterLeft>
            <Grid item xs={12}>
              <Typography variant='h4' gutterBottom>
                Contact
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContactInfo color='#00031c' />
            </Grid>
          </CenterLeft>
        </Grid>
      </Grid>
    </ContentLayout>
  );
}
