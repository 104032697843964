import React from 'react';
import { CustomAnchor } from 'styles/Links';
import { Grid, Typography as OldTypography } from '@material-ui/core';
import {
  Instagram,
  Facebook,
  GitHub,
  LinkedIn,
  Email,
  Phone,
} from '@material-ui/icons';
import styled from 'styled-components';

type Props = {
  color?: string;
};

export default function ContactInfo(props: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='tel:+4748607010'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <Phone style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    Phone
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    +47 48 60 70 10
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='mailto:kimandremidtlid@gmail.com'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <Email style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    Email
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    kimandremidtlid@gmail.com
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='https://www.linkedin.com/in/kimandremidtlid/'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <LinkedIn style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    LinkedIn
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    Kim André Midtlid
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='https://github.com/kamidtli'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <GitHub style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    GitHub
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    kamidtli
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='https://www.facebook.com/kimandre.midtlid'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <Facebook style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    Facebook
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    Kim André Midtlid
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CustomAnchor href='https://www.instagram.com/kimmidtlid/?hl=nb'>
          <Grid container alignItems='center'>
            <Grid item xs={2}>
              <Instagram style={{ color: props.color }} />
            </Grid>
            <Grid item xs={10} container direction='column'>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='h6'>
                    Instagram
                  </Typography>
                }
              </Grid>
              <Grid item>
                {
                  // @ts-ignore
                  <Typography customcolor={props.color} variant='body1'>
                    @kimmidtlid
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </CustomAnchor>
      </Grid>
    </Grid>
  );
}

const Typography = styled(OldTypography).attrs((props) => ({
  // @ts-ignore
  customcolor: props.customcolor ? props.customcolor : '#FFFFFF',
}))`
  color: ${(props) => props.customcolor};
`;
