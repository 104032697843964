import React from 'react';
import { CenterLeft } from 'styles/Layout';
import { ContentLayout } from 'styles/Layout';
import { Typography } from '@material-ui/core';
import Resume from 'assets/resume.pdf';

export default function About() {
  return (
    <div>
      <ContentLayout>
        <CenterLeft>
          <Typography variant='h4' gutterBottom>
            My CV
          </Typography>
          <Typography variant='body1'>
            You can see, and download my CV{' '}
            <a href={Resume} target='_blank' rel='noopener noreferrer'>
              here
            </a>
            .
          </Typography>
        </CenterLeft>
      </ContentLayout>
    </div>
  );
}
