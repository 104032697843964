import React, { useState } from 'react';
import Logo from 'assets/logo.svg';
import styled from 'styled-components';
import { CustomLink as Link } from 'styles/Links';
import { Menu, Face, Code, Description, Email } from '@material-ui/icons';
import {
  AppBar,
  IconButton,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  CssBaseline,
  Slide,
  useScrollTrigger,
} from '@material-ui/core';

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div>
      <CssBaseline />
      <HideOnScroll>
        <AppBar style={{ background: 'transparent', boxShadow: 'none' }}>
          <Container>
            <Link to='/'>
              <img src={Logo} alt='Logo' width={120} />
            </Link>

            <IconButton onClick={handleDrawerClick}>
              <MenuIcon fontSize='large' />
            </IconButton>
          </Container>
          <Drawer anchor='right' open={drawerOpen} onClose={handleDrawerClick}>
            <List>
              <Link to='/about' onClick={handleDrawerClick}>
                <ListItem button>
                  <ListItemIcon>
                    <Face />
                  </ListItemIcon>
                  <ListItemText primary='About me' />
                </ListItem>
              </Link>
              <Link to='/projects' onClick={handleDrawerClick}>
                <ListItem button>
                  <ListItemIcon>
                    <Code />
                  </ListItemIcon>
                  <ListItemText primary='Projects' />
                </ListItem>
              </Link>
              <Link to='/cv' onClick={handleDrawerClick}>
                <ListItem button>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary='CV' />
                </ListItem>
              </Link>
              <Link to='/contact' onClick={handleDrawerClick}>
                <ListItem button>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText primary='Contact' />
                </ListItem>
              </Link>
            </List>
          </Drawer>
        </AppBar>
      </HideOnScroll>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 0 32px;
`;

const MenuIcon = styled(Menu)`
  color: #00031c;
`;
