import React from 'react';
import styled from 'styled-components';
import { CenterLeft } from 'styles/Layout';
import { ContentLayout } from 'styles/Layout';
import { Grid, Typography } from '@material-ui/core';
import AboutMeImage from 'assets/hoppoya.png';
import HomeImage from 'assets/stavekollen.png';

export default function About() {
  return (
    <ContentLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CenterLeft>
            <Typography variant='h4' gutterBottom>
              About me
            </Typography>
            <Typography variant='body1'>
              I'm studying my 4th year of a M.Sc. in Computer Science degree at
              NTNU in Trondheim, where i specialize in Artificial Intelligence.
              Studying here offers great experience with new and interesting
              challenges everyday.
            </Typography>
          </CenterLeft>
        </Grid>
        <Grid item xs={12} md={8}>
          <ImageShadowRight alt='me' src={AboutMeImage} />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={8}>
          <ImageShadowLeft alt='home' src={HomeImage} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CenterLeft>
            <Typography variant='h4' gutterBottom>
              Home
            </Typography>
            <Typography variant='body1'>
              I'm from Sykkylven, a small place in Sunnmøre surrounded by
              mountains and fjords. When I'm at home I like to socialize with
              friends and family, go hiking, and re-charge for another semester
              in Trondheim.
            </Typography>
          </CenterLeft>
        </Grid>
      </Grid>
    </ContentLayout>
  );
}

const Divider = styled.div`
  height: 48px;
`;

const ImageShadowRight = styled.img`
  width: 100%;
  box-shadow: 10px 10px 0px 0px #00031c;
`;

const ImageShadowLeft = styled.img`
  width: 100%;
  box-shadow: -10px 10px 0px 0px #00031c;
`;
