import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { ContentLayout, Divider } from 'styles/Layout';
import ProjectCard from 'components/ProjectCard/ProjectCard';

import data from 'assets/projects.json';

export default function Projects() {
  return (
    <ContentLayout>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            Projects and other experience
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          {data.projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
              <ProjectCard
                id={project.id}
                title={project.title}
                description={project.description}
                img={project.img}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ContentLayout>
  );
}
