import React from 'react';
import styled from 'styled-components';
import ProfilePic from 'assets/me.png';
import { CustomLink as Link } from 'styles/Links';
import { TrendingFlat } from '@material-ui/icons';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export default function Home() {
  return (
    <div>
      <HeroContainer />
      <Link to='/about'>
        <HoverButton>
          <ListItem>
            <ListItemIcon>
              <TrendingFlat fontSize='large' />
            </ListItemIcon>
            <ListItemText primary='Read about me' />
          </ListItem>
        </HoverButton>
      </Link>
    </div>
  );
}

const HeroContainer = styled.div`
  background-image: url(${ProfilePic});
  z-index: -1;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const HoverButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 32px;
  padding: 16px;
  background-color: white;
`;
