import styled from 'styled-components';

export const Center = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CenterLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentLayout = styled.div`
  margin-top: 170px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 170px;
`;

export const ArticleBanner = styled.div`
  margin-left: 20%;
  margin-right: 20%;
`;

export const ContentPadded = styled.div`
  padding-top: 170px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 170px;
`;

export const Divider = styled.div`
  height: 48px;
`;
