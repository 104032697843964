import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from 'views/Home';
import About from 'views/About';
import Projects from 'views/Projects';
import Project from 'views/Project';
import CV from 'views/CV';
import Contact from 'views/Contact';

export default function Routes() {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/about' component={About} />
      <Route exact path='/projects' component={Projects} />
      <Route exact path='/projects/:id' component={Project} />
      <Route exact path='/cv' component={CV} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/f1' component={() => {
        window.location.href = 'https://f1stats-311711.web.app/'; 
        return null;
      }} />
    </Switch>
  );
}
